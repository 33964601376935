import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import { usePopper } from 'react-popper'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'gatsby'
import { ModalBase } from '@livechat/design-system'

import Layout from '../../layout/live-coding-layout'
import { SEO, Benefits, Image, Feature, Quantity } from '../../components'
import { decodeQueryString, canUseWindow, toggleScroll } from '../../utils'

import './live-coding.scss'

import SDKsIcon from '../../assets/icons/sdks.svg'
import CloudIcon from '../../assets/icons/cloud.svg'
import TechSupportIcon from '../../assets/icons/tech-support.svg'
import TwitterIcon from '../../assets/icons/twitter.svg'
import LinkedinIcon from '../../assets/icons/linkedin.svg'
import GitHubIcon from '../../assets/icons/github.svg'
import LiveChatIcon from '../../assets/icons/livechat-icon.svg'
import OgImage from '../../assets/images/livechat-live-coding-4-og.png'

import MailChimpForm from '../../components/Forms/LiveCoding'

const HoverMe = ({ children, desc, setOverlay }) => {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [visible, setVisible] = useState(false)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
  })

  const handleClick = e => {
    e.preventDefault()
    setVisible(true)
    setOverlay(true)
  }

  const handleMouseOver = () => {
    setOverlay(true)
    setVisible(true)
  }

  const handleMouseOut = () => {
    setOverlay(false)
    setVisible(false)
  }

  return (
    <>
      <a
        href="#details"
        ref={setReferenceElement}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className={`hoverMe ${visible && 'hovered'}`}
      >
        <span className="u-text-mark">{children}</span>
      </a>

      <AnimatePresence>
        {visible && (
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="popper-wrapper"
          >
            <motion.div
              animate={{
                y: 0,
                opacity: 1,
                rotateX: 0,
              }}
              initial={{
                y: 10,
                opacity: 0,
                transformPerspective: 200,
                rotateX: 4,
              }}
              exit={{
                y: -10,
                opacity: 0,
                transformPerspective: 200,
                rotateX: -4,
              }}
              transition={{ ease: 'easeOut', duration: 0.15 }}
              className={'popperBox'}
            >
              <div className="u-px-xl">{desc}</div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  )
}

const LiveCoding = () => {
  const queryString = (canUseWindow && window.location.search) || ''
  const { action } = decodeQueryString(queryString)
  const initialState = action === 'register'
  const isWide = useMedia({ minWidth: 870 })

  const [overlay, setOverlay] = useState(initialState)
  const [isFormOpen, setIsFormOpen] = useState(action === 'open-form')

  const openForm = () => {
    setIsFormOpen(true)
    toggleScroll(false)
  }

  const closeForm = () => {
    setIsFormOpen(false)
    toggleScroll(true)
  }

  return (
    <Layout
      overlay={overlay}
      setOverlay={setOverlay}
      eventDate="September 16, 2021"
      headerCta={{
        onClick: openForm,
        label: 'Save your seat',
      }}
      shouldBlockScroll={isFormOpen}
    >
      <SEO
        title="Live Coding Session with Developer Program"
        description="Take part in a online live coding session with the LiveChat Developer Program team. Put your design skills into practice and learn how to confidently implement your app's design with the LiveChat Design System."
        image={OgImage}
      />
      <section className="u-text-center u-Pt-xl u-Pb-xl">
        <section className="o-container o-columns v--two">
          <div className="o-container u-flex u-items-center u-w-full">
            <div class="video-container">
              <iframe
                title="Live Coding Session Invitation"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/wGD-KuSdS7U"
              />
            </div>
          </div>
          <div className="o-container">
            <h2 className="u-text-p2 u-mt-0 u-mb-sm md:u-text-left u-text-center u-px-xl">
              LiveChat Design System in use
            </h2>
            <div className="u-text-p5 u-px-xl u-mt-3xl">
              <p className="md:u-text-left u-text-center">
                Let's meet at another{' '}
                <HoverMe
                  setOverlay={setOverlay}
                  desc={
                    <div className="md:u-text-center u-text-center">
                      <p className="u-mb-xs">
                        <strong>Code-along session</strong>
                      </p>
                      <p>
                        Follow along with coding to get the most out of the
                        session.
                      </p>
                    </div>
                  }
                >
                  live coding session
                </HoverMe>{' '}
                organized by the LiveChat Developer Program. This time, we'll
                dive into the{' '}
                <HoverMe
                  setOverlay={setOverlay}
                  desc={
                    <div className="md:u-text-center u-text-center">
                      <p className="u-mb-xs">
                        <strong>LiveChat Design System</strong>
                      </p>
                      <p>
                        The LiveChat Design System is a library of ready-to-use
                        React components, allowing you to swiftly design any web
                        app UI.
                      </p>
                    </div>
                  }
                >
                  LiveChat Design System
                </HoverMe>{' '}
                components, explaining how to build an app aligned with the
                LiveChat look and feel in just a few moments.{' '}
              </p>

              <p className="md:u-text-left u-text-center second-text">
                {' '}
                Build your{' '}
                <HoverMe
                  setOverlay={setOverlay}
                  desc={
                    <div className="md:u-text-center u-text-center">
                      <p className="u-mb-xs">
                        <strong>Shorten your coding time</strong>
                      </p>
                      <p>
                        After the session, you'll be able to use the Design
                        System components to effortlessly implement your app's
                        design in no time.
                      </p>
                    </div>
                  }
                >
                  coding skills
                </HoverMe>
                ,<br /> become part of the LiveChat developer{' '}
                <HoverMe
                  setOverlay={setOverlay}
                  desc={
                    <div className="md:u-text-center u-text-center">
                      <p className="u-mb-xs">
                        <strong>Let's meet on Discord</strong>
                      </p>
                      <p>
                        You'll have a chance to join the growing community of
                        developers and build strong connections.
                      </p>
                    </div>
                  }
                >
                  community
                </HoverMe>
                ,<br /> and win{' '}
                <HoverMe
                  setOverlay={setOverlay}
                  desc={
                    <div className="md:u-text-center u-text-center">
                      <p className="u-mb-xs">
                        <strong>Kickstart your journey in Dev Program</strong>
                      </p>
                      <p>
                        Finish the task and submit a pull request with the
                        solution to get the opportunity of winning $300.
                      </p>
                    </div>
                  }
                >
                  prizes
                </HoverMe>
                .
              </p>
            </div>
          </div>
        </section>

        <div className="u-Mt-sm sm:u-Mt-2xl">
          <div className="o-container">
            <div className="u-flex u-flex-col sm:u-flex-row u-flex-wrap u-justify-center u-items-center u-text-left u-text-p5 u-p-md u-bg-black2 u-rounded u-shadow md:u-Mx-lg">
              <div className="sm:u-mr-xl">
                <div className="u-text-p5 u-mb-md u-mx-auto u-Mt-sm sm:u-Mt-sm event-intro-details">
                  <strong>When:</strong>{' '}
                  <span className="event-intro-info u-text-gray-800">
                    September 16, 2021 at 5 p.m. CEST
                  </span>
                </div>
                <div className="u-text-p5 u-mb-md u-mx-auto event-intro-details">
                  <strong>Where:</strong>{' '}
                  <span className="event-intro-info u-text-gray-800">
                    <a
                      href="https://www.youtube.com/watch?v=d792twx7Q0I"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      YouTube
                    </a>
                  </span>
                </div>
                <div className="u-text-p5 u-mb-md u-mx-auto u-Mb-sm sm:u-Mb-sm event-intro-details">
                  <strong>Host:</strong>{' '}
                  <span className="event-intro-info u-text-gray-800">
                    <a
                      href="https://twitter.com/Dominik77065376"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Dominik Sławkowski
                    </a>
                    , Frontend Developer
                  </span>
                </div>
              </div>
              <button
                style={{ width: '208px', height: '58px' }}
                onClick={openForm}
                className="c-btn v--livechat u-text-white u-text-p5-bold"
              >
                Save your seat
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="u-mb-sm u-Pt-sm">
        <h2 className="u-text-p2 u-Mb-md u-text-center">Win the prize</h2>
        <Feature
          title={
            <Fragment>Solve a programming task and win a gift card</Fragment>
          }
          description="After our session, the participants will receive a coding task. There's a lovely round figure up for grabs: $300 added to your Developer Account in our Developer Program. Be the first one to submit the correct solution and win the prize!"
          component={
            <Quantity
              value="$300"
              description="to start in Developer Program"
            />
          }
        />
      </section>
      <section className="o-container u-Mb-xl o-columns v--two">
        <div className="o-container">
          <h2 className="u-text-p4">Event rules</h2>
          <ol className="u-text-p6 u-text-gray-800 u-px-xl">
            <li className="u-mb-sm">
              The session will be live streamed on{' '}
              <a
                href="https://www.youtube.com/watch?v=d792twx7Q0I"
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTube
              </a>
              .
            </li>
            <li className="u-mb-sm">
              Participants are eligible to win a gift card with the value of
              $300. The funds will be transferred to the winner's account in{' '}
              <a href="/console/" target="_blank" rel="noopener noreferrer">
                Developer Console
              </a>
              .
            </li>
            <li className="u-mb-sm">
              To withdraw the funds, the winner needs to have published a paid
              app on the{' '}
              <a
                href="https://www.livechat.com/marketplace"
                target="_blank"
                rel="noopener noreferrer"
              >
                LiveChat Marketplace
              </a>
              .
            </li>
            <li className="u-mb-sm">
              How to win the prize?
              <ul>
                <li>
                  Create an account in{' '}
                  <a href="/console/" target="_blank" rel="noopener noreferrer">
                    Developer Console
                  </a>
                  .
                </li>
                <li>Take part in the live coding session.</li>
                <li>Solve the task given during the session.</li>
                <li>Submit a pull request with the solution.</li>
              </ul>
              <br />
              The first person to submit the correct solution will win the
              prize.{' '}
              <a
                href="https://developers.livechat.com/developer-terms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Developer Terms
              </a>{' '}
              apply.
            </li>
          </ol>
        </div>

        <div className="o-container">
          <h2 className="u-text-p4">
            <span className="u-text-mark1">What you'll learn</span>
          </h2>
          <ol className="u-text-p6 u-text-gray-800 u-px-xl">
            <li className="u-mb-sm">
              How to use the{' '}
              <a
                href="https://livechat.github.io/design-system/"
                target="_blank"
                rel="noopener noreferrer"
                className="u-w-fit u-mt-xs u-mb-xs u-text-p6 u-text-livechat"
              >
                LiveChat Design System{' '}
              </a>
              when building integrations for the{' '}
              <strong>LiveChat Marketplace.</strong>
            </li>
            <li className="u-mb-sm">
              What <strong>good UX patterns</strong> you should focus on when
              building web applications.
            </li>
            <li className="u-mb-sm">
              How to <strong>avoid design anti-patterns</strong> that could
              potentially decrease the clarity of your app UI.
            </li>
            <li className="u-mb-sm">
              How to create a <strong>Settings page</strong> for your app in{' '}
              <a
                href="https://developers.livechat.com/console"
                target="_blank"
                rel="noopener noreferrer"
                className="u-w-fit u-mt-xs u-mb-xs u-text-p6 u-text-livechat"
              >
                Developer Console.
              </a>
            </li>
            <br />
            With this knowledge, you'll be able to{' '}
            <strong>shorten the app development</strong> and{' '}
            <strong>effortlessly</strong> implement your desired application
            design using the LiveChat Design System components.
          </ol>
        </div>
        <div className="o-container">
          <h2 className="u-text-p4">
            <span className="u-text-mark1">About the host</span>
          </h2>
          <div className="o-feature u-mb-md">
            <div
              className="u-mb-xs u-flex"
              style={{
                justifyContent: isWide ? 'start' : 'center',
                alignItems: isWide ? 'start' : 'center',
              }}
            >
              <Image
                type="dominikSlawkowski"
                className="u-round"
                wrapperStyles={{
                  width: '180px',
                }}
              />
            </div>
            <div
              class="u-ml-auto u-mr-auto u-flex u-flex-col"
              style={{
                textAlign: isWide ? 'left' : 'center',
              }}
            >
              <p className="u-text-p5 u-mb-md">Dominik Sławkowski</p>
              <p className="u-text-p10 u-text-gray-600">
                Frontend Developer,
                <br />
                Developer Program
              </p>
              <div className="o-column v--three v--sparse">
                <a
                  href="https://twitter.com/Dominik77065376"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-text-gray-100 hover:u-text-gray-300 u-mr-xl"
                >
                  <TwitterIcon />
                </a>
                <a
                  href="https://www.linkedin.com/in/dominik-sławkowski-154912167/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-text-gray-100 hover:u-text-gray-300 u-mr-xl"
                >
                  <LinkedinIcon />
                </a>
                <a
                  href="https://github.com/dominikslawkowski"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-text-gray-100 hover:u-text-gray-300"
                >
                  <GitHubIcon />
                </a>
              </div>
            </div>
          </div>
          <p className="u-text-p6 u-text-gray-800">
            <a
              href="https://www.livechat.com/team/#dominik-slawkowski"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dominik Sławkowski
            </a>{' '}
            is a frontend developer in the LiveChat Developer Program team. He's
            a member of the CodersCrew society, and proves to be passionate
            about programming with each line of code he delivers.
          </p>
        </div>
        <div className="o-container">
          <h2 className="u-text-p4">Who we are</h2>
          <p className="u-text-p6 u-text-gray-800">
            Behind LiveChat, Inc. there is a team of{' '}
            <a
              href="https://www.livechat.com/team/"
              target="_blank"
              rel="noopener noreferrer"
            >
              passionate people
            </a>{' '}
            building online customer service software with online chat, help
            desk software, chatbot, and web analytics capabilities.{' '}
          </p>
          <p className="u-text-p6 u-text-gray-800">
            With a suite of four products (
            <a
              href="https://www.livechat.com/?utm_source=lp_lcs&utm_medium=link&utm_id=lcs4"
              target="_blank"
              rel="noopener noreferrer"
            >
              LiveChat
            </a>
            ,{' '}
            <a
              href="https://www.chatbot.com/?utm_source=lp_lcs&utm_medium=link&utm_id=lcs4"
              target="_blank"
              rel="noopener noreferrer"
            >
              ChatBot
            </a>
            ,{' '}
            <a
              href="https://www.helpdesk.com/?utm_source=lp_lcs&utm_medium=link&utm_id=lcs4"
              target="_blank"
              rel="noopener noreferrer"
            >
              HelpDesk
            </a>
            ,{' '}
            <a
              href="https://www.knowledgebase.ai/?utm_source=lp_lcs&utm_medium=link&utm_id=lcs4"
              target="_blank"
              rel="noopener noreferrer"
            >
              KnowledgeBase
            </a>
            ) and their powerful APIs, we power customer communication for
            33,000 companies in 150 countries.
          </p>
          <p className="u-text-p6 u-text-gray-800">
            <Link to="/">LiveChat Platform</Link> is a range of products and
            services that can be used to build a variety of communication tools
            for businesses. Our <Link to="/">Developer Program</Link> and{' '}
            <a
              href="https://www.livechat.com/marketplace/?utm_source=lp_lcs&utm_medium=link&utm_id=lcs4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Marketplace{' '}
            </a>
            create an open ecosystem for developers, partners, and customers.
          </p>
        </div>
      </section>

      <section className="u-bg-black2 u-Py-xl u-Mt-2xl u-Mb-md">
        <h2 className="u-text-p3 u-Mb-md u-text-center">Before you go...</h2>
        <Benefits
          content={[
            {
              icon: SDKsIcon,
              label: 'Part 1: Building apps 101',
              description: (
                <>
                  See{' '}
                  <a
                    href="https://www.youtube.com/watch?v=SjqCBgAaEbc&feature=youtu.be"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    part 1
                  </a>{' '}
                  if you're just starting out with LiveChat apps or need to dust
                  off your knowledge.
                </>
              ),
            },
            {
              icon: TechSupportIcon,
              label: 'Part 2: Authorizing web apps',
              description: (
                <>
                  Watch{' '}
                  <a
                    href="https://youtu.be/FDmLqEiCH60"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    part 2
                  </a>{' '}
                  to get a good understanding of web app authorization in
                  LiveChat.
                </>
              ),
            },
            {
              icon: CloudIcon,
              label: 'Part 3: Authorizing server-side apps',
              description: (
                <>
                  Check{' '}
                  <a
                    href="https://youtu.be/ei8YG9mLdfI"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    part 3
                  </a>{' '}
                  to learn the details of the LiveChat server-side app
                  authorization.
                </>
              ),
            },
          ]}
        />
        <div className="u-text-center">
          <h3 className="u-text-p5 u-Mb-md u-text-center">
            Got a question? Ask us on Discord!
            <br />
          </h3>
          <a
            href="https://discord.gg/gRzwSaCxg4"
            target="_blank"
            rel="noopener noreferrer"
            className="c-btn v--livechat u-text-white u-text-p6-bold"
          >
            Join Discord
          </a>
        </div>
      </section>
      <section className="u-text-center u-My-xl">
        <LiveChatIcon />
        <p className="u-text-p8-bold u-mt-sm outro">
          Live coding session by the <Link to="/">LiveChat Platform</Link>
        </p>
        <p className="u-text-p8 outro">
          Our vision is to help innovators build the future of the
          communication. Sounds interesting?{' '}
          <a href="https://www.livechat.com/careers/">Join us</a>!
        </p>
      </section>

      {isFormOpen && (
        <div className="modal-form">
          <ModalBase
            onClose={closeForm}
            style={{ width: '500px', height: '500px' }}
          >
            <div style={{ margin: 'auto', overflow: 'auto' }}>
              <MailChimpForm />
            </div>
          </ModalBase>
        </div>
      )}
    </Layout>
  )
}

HoverMe.propTypes = {
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setOverlay: PropTypes.func,
}

export default LiveCoding
