import React, { useState } from 'react'
import { Select } from '@livechat/design-system'
import { canUseWindow } from '../../../utils'
import Image from '../../Image'
import './style.less'

const items = [
  {
    key: '1',
    props: {
      name: 'Social media (Facebook, Twitter, LinkedIn)',
      value: 'Social media (Facebook, Twitter, LinkedIn)',
    },
  },
  {
    key: '2',
    props: {
      name: 'Newsletter',
      value: 'Newsletter',
    },
  },
  {
    key: '3',
    props: {
      name: 'External websites (dev.to, hackernoon.com)',
      value: 'External websites (dev.to, hackernoon.com)',
    },
  },
  {
    key: '4',
    props: { name: 'From a friend', value: 'From a friend' },
  },
  { key: '5', props: { name: 'LiveChat website', value: 'LiveChat website' } },
]

const MailChimpForm = () => {
  const [selectedItem, setSelectedItem] = useState(null)

  const getItemBody = props => {
    if (!props) {
      return null
    }
    // eslint-disable-next-line react/prop-types
    return <div id={props.value}>{props.name}</div>
  }

  const getSelectedItemBody = props => {
    // eslint-disable-next-line react/prop-types
    return <div id={props.value}>{props.name}</div>
  }

  return (
    <div id="mc_embed_signup" style={{ height: '100%' }}>
      <form
        action="https://livechat.us2.list-manage.com/subscribe/post?u=9c5eb54aa9b2efe23531b3e09&id=8e023bb951"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        novalidate
        onSubmit={() => {
          canUseWindow &&
            window.dataLayer &&
            window.dataLayer.push({ event: 'eventRegistrationForm' })
        }}
      >
        <div id="mc_embed_signup_scroll" className="u-flex u-flex-col">
          <div>
            <h2 className="u-text-black2">Save your seat</h2>
            <div className="mc-field-group u-mb-md">
              <label for="mce-FNAME">Full Name *</label>
              <input
                type="text"
                name="FNAME"
                className="required"
                id="mce-FNAME"
                placeholder="Type your name"
                required
              />
            </div>
            <div className="mc-field-group u-mb-md">
              <label for="mce-EMAIL">Email Address *</label>
              <input
                type="email"
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                placeholder="Type your email"
                required
              />
            </div>
            <div className="mc-field-group u-mb-md">
              <label for="MERGE2">
                How did you find out about this event? *{' '}
              </label>
              <input
                type="hidden"
                id="MERGE2"
                className="required"
                name="MERGE2"
                value={
                  items.find(item => item.key === selectedItem)?.props?.value
                }
              />
              <Select
                className="form-select"
                items={items}
                onItemSelect={setSelectedItem}
                getItemBody={getItemBody}
                placeholder="Select from the list"
                getSelectedItemBody={getSelectedItemBody}
                selected={selectedItem}
                required
              />
            </div>
            <div
              style={{ display: 'none' }}
              id="mergeRow-gdpr"
              className="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group"
            >
              <div className="content__gdpr">
                <label>Marketing Permissions</label>
                <p>
                  Please select all the ways you would like to hear from
                  LiveChat:
                </p>
                <fieldset
                  className="mc_fieldset gdprRequired mc-field-group"
                  name="interestgroup_field"
                >
                  <label className="checkbox subfield" for="gdpr_106">
                    <input
                      type="checkbox"
                      id="gdpr_106"
                      name="gdpr[106]"
                      value="Y"
                      className="av-checkbox "
                    />
                    <span>Email</span>{' '}
                  </label>
                </fieldset>
                <p>
                  You can unsubscribe at any time by clicking the link in the
                  footer of our emails. For information about our privacy
                  practices, please visit our website.
                </p>
              </div>
              <div className="content__gdprLegal">
                <p>
                  We use Mailchimp as our marketing platform. By clicking below
                  to subscribe, you acknowledge that your information will be
                  transferred to Mailchimp for processing.{' '}
                  <a
                    href="https://mailchimp.com/legal/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more about Mailchimp's privacy practices here.
                  </a>
                </p>
              </div>
            </div>
            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: 'none' }}
              />
              <div
                className="response"
                id="mce-success-response"
                style={{ display: 'none' }}
              />
            </div>
            <div
              style={{ position: 'absolute', left: '-5000px' }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_9c5eb54aa9b2efe23531b3e09_9333e17676"
                tabindex="-1"
                value=""
              />
            </div>
          </div>

          <div className="u-flex">
            <div
              style={{
                position: 'relative',
                left: '25px',
                top: '6px',
              }}
            >
              <Image
                type="pointingFinger"
                className=""
                wrapperStyles={{
                  width: '200px',
                  height: '102px',
                }}
              />
            </div>

            <div className="clear u-w-full u-flex u-justify-end u-mt-auto">
              <button
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button c-btn btn-blue u-text-p4-bold"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default MailChimpForm
